export const dataPreviewColumns = {
    "Facility/Unit Attributes" :
    {
        "State"	: "95px",
        "Facility Name" :	"130px",
        "Facility ID"	: "110px",
        "Unit ID" :	"90px",
        "Associated Stacks"	: "160px",
        "Year" :	"90px",
        "Program Code" :	"135px",
        "EPA Region" :	"115px",
        "NERC Region"	:"125px",
        "County" :	"110px",
        "County Code"	: "125px",
        "FIPS Code" :	"105px",
        "Source Category" :	"150px",
        "Latitude" :"120px",
        "Longitude"	: "130px",
        "Owner/Operator" :	"175px",
        "SO2 Phase"	: "110px",
        "NOx Phase"	: "110px",
        "Primary Fuel Type" :"160px",
        "Secondary Fuel Type" :	"180px",
        "Unit Type"	: "120px",
        "SO2 Controls"	: "150px",
        "PM Controls"	: "150px",
        "NOx Controls"	: "170px",
        "Hg Controls"	: "170px",
        "Commercial Operation Date" :	"215px",
        "Operating Status" :	"155px",
        "Max Hourly HI Rate (mmBtu/hr)" :	"215px",
        "Associated Generators & Nameplate Capacity (MWe)"	: "265px"
    },
    "Allowance Based":{
        "Program Code": "120px",
        "Year": "90px",
        "Account Number": "155px",
        "Account Name": "135px",
        "Facility Name": "130px",
        "Facility ID": "110px",
        "Units Affected": "135px",
        "Compliance Year Allowances Allocated": "205px",
        "Total Allowances Held at Trading Deadline": "205px",
        "Emissions (short tons)": "190px",
        "Other Deductions": "160px",
        "Total Allowances Deducted": "205px",
        "Allowances Carried Over": "205px",
        "Excess Emissions (short tons)": "205px",
        "Owner/Operator": "190px",
        "State": "105px",
        "Banked Allowances": "170px",
        "Current Held": "125px",
        "Total Required Deductions": "205px",
        "Current Deductions": "170px",
        "Deduction 1 to 1": "150px",
        "Deduction 2 to 1": "150px",
    },
    "Emissions Based":{
        "Year":	"90px",
        "Facility Name":	"130px",
        "Facility Id":	"110px",
        "Unit ID":	"90px",
        "Owner/Operator":	"190px",
        "State":	"95px",
        "Compliance Approach":	"190px",
        "Avg Plan ID":	"160px",
        "Emissions Limit (lb/mmBtu)":	"200px",
        "Actual Emissions Rate (lb/mmBtu)"	:"200px",
        "Avg Plan Actual Rate (lb/mmBtu)":	"200px",
        "In Compliance?":	"150px"
    },
    "Holdings":{
        "Account Number":	"155px",
        "Account Name":	"135px",
        "Facility ID":	"110px",
        "Program":	"135px",
        "Vintage Year":	"195px",
        "Total Block":	"190px",
        "Start Block":	"175px",
        "End Block":	"165px",
        "State":	"105px",
        "EPA Region": "115px",
        "Owner/Operator":	"190px",
        "Account Type":	"130px",
    },
    "Account Information":{
        "Account Number":	"155px",
        "Account Name":	"135px",
        "Program Code":	"135px",
        "Account Type":	"130px",
        "Facility ID":	"110px",
        "Unit ID":	"90px",
        "Owner/Operator":	"190px",
        "State":	"105px",
        "EPA Region":	"115px",
        "NERC Region":	"125px"
    },
    "Transactions":{
        "Program Code":	"120px",
        "Transaction ID":	"140px",
        "Transaction Total"	:"155px",
        "Account Number (Transferor) ":	"185px",
        "Account Name (Transferor)":	"185px",
        "Account Type (Transferor) ":	"185px",
        "Facility Name (Transferor)":	"185px",
        "Facility ID (Transferor) ":	"185px",
        "State (Transferor)"	:"155px",
        "EPA Region (Transferor)"	:"185px",
        "Source Category (Transferor)":	"185px",
        "Owner (Transferor)":	"165px",
        "Account Number (Transferee)"	:"185px",
        "Account Name (Transferee)":	"185px",
        "Account Type (Transferee)":	"185px",
        "Facility Name (Transferee)":	"185px",
        "Facility ID (Transferee)":	"185px",
        "State (Transferee)":	"160px",
        "EPA Region (Transferee)":	"185px",
        "Source Category (Transferee)"	: "185px",
        "Owner (Transferee)":	"170px",
        "Transaction Date":	"155px",
        "Allowance Vintage Year":	"195px",
        "Serial Number Start":	"175px",
        "Serial Number End":	"165px",
        "Allowance Total Block"	:"190px",
        "Transaction Type":	"155px"
    },
    "Hourly Emissions":{
        "State":	"95px",
        "Facility Name":	"130px",
        "Facility ID":	"110px",
        "Unit ID"	:"90px",
        "Associated Stacks"	:"160px",
        "Date":	"110px",
        "Hour":	"100px",
        "Operating Time":	"145px",
        "Gross Load (MW)":	"145px",
        "Steam Load (1000 lb/hr)"	: "145px",
        "SO2 Mass (lbs)":	"135px",
        "SO2 Mass Measure Indicator":	"145px",
        "SO2 Rate (lbs/mmBtu)":	"150px",
        "SO2 Rate Measure Indicator":	"145px",
        "NOx Mass (lbs)":	"140px",
        "NOx Mass Measure Indicator":	"145px",
        "NOx Rate (lbs/mmBtu)":	"145px",
        "NOx Rate Measure Indicator":	"145px",
        "CO2 Mass (short tons)":	"145px",
        "CO2 Mass Measure Indicator":	"145px",
        "CO2 Rate (short tons/mmBtu)":	"150px",
        "CO2 Rate Measure Indicator":	"145px",
        "Heat Input (mmBtu)":	"145px",
        "Primary Fuel Type":	"145px",
        "Secondary Fuel Type"	: "145px",
        "Unit Type":	"120px",
        "SO2 Controls":	"150px",
        "PM Controls":	"150px",
        "NOx Controls":	"170px",
        "Hg Controls":	"170px",
        "Program Code":	"135px",
        'HCL Input Rate (lb/mmBtu)': "145px",
        'HF Input Rate (lb/mmBtu)': "145px",
    },
    "Daily Emissions":{
        "State"	:"95px",
        "Facility Name"	:"130px",
        "Facility ID"	:"110px",
        "Unit ID"	:"90px",
        "Associated Stacks"	:"160px",
        "Gross Load (MWh)":	"160px",
        "Steam Load (1000 lb)":	"180px",
        "SO2 Mass (short tons)":"185px",
        "SO2 Rate (lbs/mmBtu)":	"190px",
        "NOx Mass (short tons)":	"185px",
        "NOx Rate (lbs/mmBtu)":	"190px",
        "CO2 Mass (short tons)"	:"185px",
        "CO2 Rate (short tons/mmBtu)":	"205px",
        "Heat Input (mmBtu)":	"175px",
        "Primary Fuel Type"	: "160px",
        "Secondary Fuel Type":	"180px",
        "Unit Type":	"120px",
        "SO2 Controls":	"150px",
        "PM Controls":	"150px",
        "NOx Controls":	"170px",
        "Hg Controls"	:"170px",
        "Program Code"	:"135px",
        "Date"	:"110px",
        "Sum of the Operating Time":	"205px",
        "Operating Time Count":	"190px"
    },
    "Monthly Emissions":{
        "State":	"95px",
        "Facility Name":	"130px",
        "Facility ID":	"110px",
        "Unit ID":	"90px",
        "Associated Stacks"	:"160px",
        "Gross Load (MWh)":	"160px",
        "Steam Load (1000 lb)":	"180px",
        "SO2 Mass (short tons)":	"185px",
        "SO2 Rate (lbs/mmBtu)":	"190px",
        "NOx Mass (short tons)":	"185px",
        "NOx Rate (lbs/mmBtu)":	"190px",
        "CO2 Mass (short tons)":	"185px",
        "CO2 Rate (short tons/mmBtu)":	"205px",
        "Heat Input (mmBtu)":	"175px",
        "Primary Fuel Type":	"160px",
        "Secondary Fuel Type"	:"180px",
        "Unit Type":	"120px",
        "SO2 Controls":	"150px",
        "PM Controls"	:"150px",
        "NOx Controls":	"170px",
        "Hg Controls"	: "170px",
        "Program Code":	"135px",
        "Year":	"90px",
        "Month"	: "110px",
        "Sum of the Operating Time":	"205px",
        "Operating Time Count":	"190px"
    },
    "Quarterly Emissions":{
        "State":	"95px",
        "Facility Name":	"130px",
        "Facility ID":	"110px",
        "Unit ID":	"90px",
        "Associated Stacks"	:"160px",
        "Gross Load (MWh)":	"160px",
        "Steam Load (1000 lb)":	"180px",
        "SO2 Mass (short tons)":	"185px",
        "SO2 Rate (lbs/mmBtu)":	"190px",
        "NOx Mass (short tons)":	"185px",
        "NOx Rate (lbs/mmBtu)":	"190px",
        "CO2 Mass (short tons)":	"185px",
        "CO2 Rate (short tons/mmBtu)":	"205px",
        "Heat Input (mmBtu)":	"175px",
        "Primary Fuel Type":	"160px",
        "Secondary Fuel Type"	:"180px",
        "Unit Type":	"120px",
        "SO2 Controls":	"150px",
        "PM Controls"	:"150px",
        "NOx Controls":	"170px",
        "Hg Controls"	: "170px",
        "Program Code":	"135px",
        "Year":	"90px",
        "Quarter"	: "115px",
        "Sum of the Operating Time":	"205px",
        "Operating Time Count":	"190px"
    },
    "Ozone Season Emissions":{
        "State":	"95px",
        "Facility Name":	"130px",
        "Facility ID":	"110px",
        "Unit ID":	"90px",
        "Associated Stacks"	:"160px",
        "Gross Load (MWh)":	"160px",
        "Steam Load (1000 lb)":	"180px",
        "SO2 Mass (short tons)":	"185px",
        "SO2 Rate (lbs/mmBtu)":	"190px",
        "NOx Mass (short tons)":	"185px",
        "NOx Rate (lbs/mmBtu)":	"190px",
        "CO2 Mass (short tons)":	"185px",
        "CO2 Rate (short tons/mmBtu)":	"205px",
        "Heat Input (mmBtu)":	"175px",
        "Primary Fuel Type":	"160px",
        "Secondary Fuel Type"	:"180px",
        "Unit Type":	"120px",
        "SO2 Controls":	"150px",
        "PM Controls"	:"150px",
        "NOx Controls":	"170px",
        "Hg Controls"	: "170px",
        "Program Code":	"135px",
        "Year":	"90px",
        "Sum of the Operating Time":	"205px",
        "Operating Time Count":	"190px"
    },
    "Annual Emissions":{
        "State":	"95px",
        "Facility Name":	"130px",
        "Facility ID":	"110px",
        "Unit ID":	"90px",
        "Associated Stacks"	:"160px",
        "Gross Load (MWh)":	"160px",
        "Steam Load (1000 lb)":	"180px",
        "SO2 Mass (short tons)":	"185px",
        "SO2 Rate (lbs/mmBtu)":	"190px",
        "NOx Mass (short tons)":	"185px",
        "NOx Rate (lbs/mmBtu)":	"190px",
        "CO2 Mass (short tons)":	"185px",
        "CO2 Rate (short tons/mmBtu)":	"205px",
        "Heat Input (mmBtu)":	"175px",
        "Primary Fuel Type":	"160px",
        "Secondary Fuel Type"	:"180px",
        "Unit Type":	"120px",
        "SO2 Controls":	"150px",
        "PM Controls"	:"150px",
        "NOx Controls":	"170px",
        "Hg Controls"	: "170px",
        "Program Code":	"135px",
        "Year":	"90px",
        "Sum of the Operating Time":	"205px",
        "Operating Time Count":	"190px"
    }
};
