//filter updates
export const UPDATE_TIME_PERIOD = "UPDATE_TIME_PERIOD";
export const UPDATE_PROGRAM_SELECTION = "UPDATE_PROGRAM_SELECTION";
export const UPDATE_FACILITY_SELECTION ="UPDATE_FACILITY_SELECTION";
export const UPDATE_UNIT_TYPE_SELECTION = "UPDATE_UNIT_TYPE_SELECTION";
export const UPDATE_FUEL_TYPE_SELECTION = "UPDATE_FUEL_TYPE_SELECTION";
export const UPDATE_STATE_SELECTION = "UPDATE_STATE_SELECTION";
export const UPDATE_CONTROL_TECHNOLOGY_SELECTION = "UPDATE_CONTROL_TECHNOLOGY_SELECTION";
export const UPDATE_ACCOUNT_TYPE_SELECTION = "UPDATE_ACCOUNT_TYPE_SELECTION";
export const UPDATE_ACCOUNT_NAME_NUMBER_SELECTION = "UPDATE_ACCOUNT_NAME_NUMBER_SELECTION";
export const UPDATE_OWNER_OPERATOR_SELECTION = "UPDATE_OWNER_OPERATOR_SELECTION";
export const UPDATE_TRANSACTION_TYPE_SELECTION = "UPDATE_TRANSACTION_TYPE_SELECTION";
export const UPDATE_SOURCE_CATEGORY_SELECTION = "UPDATE_SOURCE_CATEGORY_SELECTION";
export const UPDATE_FILTER_CRITERIA = "UPDATE_FILTER_CRITERIA";
export const RESET_FILTER_CRITERIA_ITEMS = "RESET_FILTER_CRITERIA_ITEMS";

//load filter data
export const LOAD_PROGRAMS_SUCCESS = "LOAD_PROGRAMS_SUCCESS";
export const LOAD_FACILITIES_SUCCESS = "LOAD_FACILITIES_SUCCESS";
export const LOAD_UNIT_TYPES_SUCCESS = "LOAD_UNIT_TYPES_SUCCESS";
export const LOAD_FUEL_TYPES_SUCCESS = "LOAD_FUEL_TYPES_SUCCESS";
export const LOAD_STATES_SUCCESS = "LOAD_STATES_SUCCESS";
export const LOAD_CONTROL_TECHNOLOGIES_SUCCESS = "LOAD_CONTROL_TECHNOLOGIES_SUCCESS";
export const LOAD_ACCOUNT_TYPES_SUCCESS = "LOAD_ACCOUNT_TYPES_SUCCESS";
export const LOAD_ACCOUNT_NAME_NUMBER_SUCCESS = "LOAD_ACCOUNT_NAME_NUMBER_SUCCESS";
export const LOAD_OWNER_OPERATOR_SUCCESS = "LOAD_OWNER_OPERATOR_SUCCESS";
export const LOAD_TRANSACTION_TYPE_SUCCESS = "LOAD_TRANSACTION_TYPE_SUCCESS";
export const LOAD_SOURCE_CATEGORY_SUCCESS = "LOAD_SOURCE_CATEGORY_SUCCESS";

//filter reset
export const RESET_FILTER = "RESET_FILTER";

//applied filter operations
export const ADD_APPLIED_FILTER = "ADD_APPLIED_FILTER";
export const REMOVE_APPLIED_FILTER = "REMOVE_APPLIED_FILTER";

//update cdd data type, subytpe, and aggregation
export const UPDATE_SELECTED_DATATYPE = "UPDATE_SELECTED_DATATYPE";
export const UPDATE_SELECTED_DATASUBTYPE = "UPDATE_SELECTED_DATASUBTYPE";
export const UPDATE_SELECTED_AGGREGATION = "UPDATE_SELECTED_AGGREGATION";

//API call
export const BEGIN_API_CALL = "BEGIN_API_CALL";

//API errors
export const API_ERRORS = "API_ERRORS";

//hideNav
export const HIDE_NAV = "HIDE_NAV";

//data preview
export const LOAD_DATA_PREVIEW_SUCCESS = "LOAD_DATA_PREVIEW_SUCCESS";
export const RESET_DATA_PREVIEW = "RESET_DATA_PREVIEW";

//Filter logic
export const LOAD_FILTER_MAPPING_SUCCESS = "LOAD_FILTER_MAPPING_SUCCESS";

//Bulkd data files
export const LOAD_BULK_DATA_FILES_SUCCESS = "LOAD_BULK_DATA_FILES_SUCCESS";
export const UPDATE_BULK_DATA_FILES = "UPDATE_BULK_DATA_FILES";
